



































































































































































































































































































































































































































































































































































































































































































import utils from '@/shared/mixins/utils'
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import variables from '@/shared/variables'
import normsData from '@/assets/data/norms-data.json'
import Confirmation from '@/shared/components/Confirmation.vue'
import { UserRole } from '@/shared/enum/general-enum'
import { NormType } from '@/shared/enum/norms-enum'
import moment from 'moment'
import DocumentationLink from '@/shared/components/DocumentationLink.vue'
export default defineComponent({
    props: {
        entityDetails: {
            type: Object,
        },
        enableEditSaveNext: {},
        isMSR: {
            type: Boolean,
        },
    },
    components: {
        Confirmation,
        DocumentationLink,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const labels = ref({})
        const editable = ref(false)
        const superAdminEdit = ref(false)
        const createdByUser = ref(false)
        const normtypes = ref([])
        const displaySaveConfirmation = ref(false)
        const displayCancelConfirmation = ref(false)
        const saveType = ref('')
        const formData = ref({
            name: '',
            displayName: '',
            description: '',
            longDescription: '',
            publicationDate: new Date(),
            version: '',
            normType: '',
            normTypeOther: '',
            owner: null,
            documentationUrl: '',
        })
        const { role } = useState(['role'])
        const errors = ref({
            name: { missingValue: false },
            owner: { expire: false },
            normTypeOther: { missingValue: false },
            documentationUrl: { invalidURL: false },
        })
        const labelInfomationOpDescription = ref(null)
        const labelInfomationOpLongDescription = ref(null)
        const labelInfomationOpName = ref(null)
        const labelInfomationOpDisplayName = ref(null)
        const labelInfomationOpVersion = ref(null)
        const labelInfomationOpCreatedBy = ref(null)
        const labelInfomationOpPublicationDate = ref(null)
        const labelInfomationOpPublishedBy = ref(null)
        const labelInfomationOpNormType = ref(null)
        const labelInfomationOpPaid = ref(null)
        const labelInfomationOpRequiredForSectors = ref(null)
        const labelInfomationOpDocumentationUrl = ref(null)
        const employees = ref([])
        const { users } = useState(['users'])
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })

        //  validating form
        const validateForm = () => {
            // missingValue checks
            errors.value = {
                name: { missingValue: false },
                normTypeOther: { missingValue: false },
                owner: { expire: false },
                documentationUrl: { invalidURL: false },
            }
            errors.value.name.missingValue = utils.validateFields(formData.value.name, 'string')
            // if norm type is selected to Other, normTypeOther input cannot be empty
            if (formData.value.normType === NormType.OTHER)
                errors.value.normTypeOther.missingValue = utils.validateFields(
                    formData.value.normTypeOther,
                    'string'
                )
            /* check whether selected owner is expired or not.
            Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
            In this case, it will ask to select an active user
                */
            const owner = employees.value.find((employee) => employee.id === formData.value.owner)
            if (owner) errors.value.owner.expire = owner.userExpired

            if (formData.value.documentationUrl) {
                // If documentation URL is set, it must be a valid documentation url
                errors.value.documentationUrl.invalidURL = utils.validateFields(
                    formData.value.documentationUrl,
                    'documentationURL'
                )
            }
        }

        /* Enable edit button when
            - User is not already in edit mode
            - role is `Admin`, `Perium admin` or `Developer admin`
            - norm details(`entityDetails`) are available
            - norm is created by user */
        const enableEditNormButton = computed(() => {
            return (
                !editable.value &&
                (role.value === UserRole.ADMIN ||
                    role.value === UserRole.PERIUM_ADMIN ||
                    role.value === UserRole.DEVELOPER_ADMIN) &&
                props.entityDetails &&
                createdByUser.value
            )
        })

        // norm type onChange event
        const changeNormType = () => {
            // if norm type is not 'Other', reset `normTypeOther`, `publicationDate` and `version`,  field
            if (formData.value.normType !== NormType.OTHER) {
                formData.value.normTypeOther = ''
                formData.value.publicationDate = null
                formData.value.version = ''
            } else {
                // set publicationDate to today by default when user select 'Other' as norm type
                formData.value.publicationDate = new Date()
            }
        }

        const setDropdownValues = () => {
            normtypes.value = []

            normsData.NORM_TYPE.map((biv) => {
                normtypes.value.push({
                    key: biv.KEY,
                    value: t(biv.VALUE, language.value),
                })
            })
            // set employee list
            employees.value = []
            users.value &&
                users.value.map((user) => {
                    // get user logged in organisation(current org)
                    const currentOrganisation = user.organisations.filter(
                        (org) => org.id === Number(organisationId)
                    )
                    // get users who are available in current organisation with 'Employee' role
                    const filteredUsers =
                        currentOrganisation[0] &&
                        !!currentOrganisation[0].roles.find(
                            (role) => role.name === UserRole.EMPLOYEE
                        )
                    if (filteredUsers) {
                        employees.value.push(user)
                    }
                })
        }

        // set data for form
        const setFormData = () => {
            const normDetails = props.entityDetails
            formData.value = {
                name: normDetails.name,
                displayName: normDetails.displayName,
                description: normDetails.description,
                longDescription: normDetails.longDescription,
                publicationDate: normDetails.publicationDate
                    ? new Date(normDetails.publicationDate)
                    : new Date(),
                version: normDetails.version,
                normType: normDetails.normType,
                normTypeOther: normDetails.normTypeOther,
                owner:
                    normDetails.owner && normDetails.owner.id !== 5 ? normDetails.owner.id : null,
                documentationUrl: normDetails.documentationUrl,
            }
        }

        // go to record (previous/ next)
        const goToRecord = (typeOfButton: string) => {
            emit('go-to-record', { no: props.entityDetails.no, typeOfButton })
        }

        // enable edit fields
        const enableEdit = () => {
            editable.value = true
        }
        // cancel edit
        const cancelEdit = () => {
            editable.value = false
            superAdminEdit.value = false
            if (props.entityDetails) {
                setFormData()
            } else {
                emit('close-create-new')
            }
        }
        const setFormattedDetails = () => {
            const createdByUsername =
                props.entityDetails.created_by && props.entityDetails.created_by.username
            createdByUser.value = createdByUsername !== variables.DEFAULT_USERNAME ? true : false
        }
        // save norm data
        const save = (type) => {
            // validate form
            validateForm()
            // check if the form has errors
            if (utils.hasErrors(errors.value)) {
                return
            }
            if (props.entityDetails) {
                emit('save-norm', {
                    id: props.entityDetails.id,
                    formData: formData.value,
                    createdBy: createdByUser.value,
                    type,
                    no: props.entityDetails.no,
                })
            } else {
                emit('save-norm', {
                    formData: formData.value,
                })
            }
        }
        // open save confirmation
        const openSaveConfirmation = (type) => {
            saveType.value = type
            if (props.entityDetails) {
                displaySaveConfirmation.value = true
            } else {
                // for new create entity
                save(type)
            }
        }
        // open cancel confirmation
        const openCancelConfirmation = () => {
            if (props.entityDetails) {
                displayCancelConfirmation.value = true
            } else {
                // for new create entity
                cancelEdit()
            }
        }

        // on success save confirm
        const successSaveConfirmation = () => {
            displaySaveConfirmation.value = false
            save(saveType.value)
        }

        // on success cancel confirm
        const successCancelConfirmation = () => {
            displayCancelConfirmation.value = false
            cancelEdit()
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)
            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        // watch for language chaneges and update labels
        watch(
            language,
            () => {
                setDropdownValues()
            },
            {
                immediate: true,
            }
        )

        onMounted(async () => {
            if (props.entityDetails) {
                setFormData()
                setFormattedDetails()
            }
        })

        return {
            t,
            language,
            utils,
            labels,
            goToRecord,
            editable,
            enableEdit,
            superAdminEdit,
            createdByUser,
            normtypes,
            formData,
            cancelEdit,
            save,
            role,
            variables,
            displaySaveConfirmation,
            displayCancelConfirmation,
            saveType,
            openSaveConfirmation,
            openCancelConfirmation,
            successSaveConfirmation,
            successCancelConfirmation,
            errors,
            toggleInformation,
            labelInfomationOpDescription,
            labelInfomationOpLongDescription,
            labelInfomationOpName,
            labelInfomationOpDisplayName,
            labelInfomationOpVersion,
            labelInfomationOpCreatedBy,
            labelInfomationOpPublicationDate,
            labelInfomationOpPublishedBy,
            labelInfomationOpNormType,
            labelInfomationOpPaid,
            labelInfomationOpRequiredForSectors,
            labelInfomationOpDocumentationUrl,
            UserRole,
            normsData,
            NormType,
            changeNormType,
            moment,
            enableEditNormButton,
            employees,
        }
    },
})
