const NORMS = `
    id
    description
    name
    version,
    publicationDate
    normType
    displayName
`

const NORM_DETAILS = `
    id
    order
    owner{
        id
        firstName
        lastName
    }
    name
    displayName
    description
    longDescription
    version
    publicationDate
    publishedBy
    paid
    requiredForSectors
    documentationUrl
    normType
    normTypeOther
    created_by{
        username
        firstName
        lastName
    }
`

export default { NORMS, NORM_DETAILS }
